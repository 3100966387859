import { Injectable, inject } from '@angular/core';
import { AlgoliaService } from '@core/services/algolia/algolia.service';
import { environment } from '@environment';
import { DependencyType } from '@models/constraint';
import { PickableDependencyItem } from '@models/pickable-dependency-item';
import { Transformer } from '@models/transformer';
import { Question, QuestionType } from '@wizbii/models';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DependenciesService {
  dependencies$ = new BehaviorSubject<PickableDependencyItem[] | undefined>(undefined);
  questions$ = new BehaviorSubject<PickableDependencyItem[] | undefined>(undefined);
  dependenciesLoaded$ = new BehaviorSubject<boolean>(false);

  filteredDependencyTypes: DependencyType[] = [QuestionType.City, QuestionType.Country];

  readonly #algoliaService = inject(AlgoliaService);

  constructor() {
    this.getDependencies();
  }

  getDependencies(searchTerms = ''): void {
    const transformers$ = this.#algoliaService
      .fetchIndex<Transformer>(`${environment.algolia.index.transformers}_published`, searchTerms, [], 1000)
      .pipe(
        map((response) =>
          response.hits.map((transformer): PickableDependencyItem => {
            return {
              type: transformer.type,
              name: transformer.name,
              id: transformer.transformerId,
              resourceType: 'transformer',
            };
          })
        )
      );
    const questions$ = this.#algoliaService
      .fetchIndex<Question>(`${environment.algolia.index.questions}_published`, searchTerms, [], 1000)
      .pipe(
        map((response: any) =>
          response.hits.map((question: any): PickableDependencyItem => {
            return {
              type: question.type,
              name: question.name,
              id: question.questionId,
              resourceType: 'question',
              description: question.description,
            };
          })
        ),
        tap((questions) => {
          this.questions$.next(questions);
        }),
        map((questions: PickableDependencyItem[]) =>
          questions.filter((question) => !this.filteredDependencyTypes.includes(question.type))
        )
      );

    forkJoin([questions$, transformers$])
      .pipe(map(([questions, transformers]) => [...questions, ...transformers]))
      .subscribe((response) => {
        this.dependencies$.next(response);
        this.dependenciesLoaded$.next(true);
      });
  }

  get(dependencyId: string): PickableDependencyItem | undefined {
    return this.dependencies$.value?.find((i) => i.id === dependencyId);
  }
}
